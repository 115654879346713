:root {
  --alethea-green: #05707d;
  --alethea-orange: #f36c24;
  --alethea-orange-alpha: #f36c2420;
  --alethea-light-green: #86d0c8;
  --alethea-light-green-alpha: #86d0c964;
  --default-grey: #555555;
  --light-grey: #dddddd;
  --danger-red: #dc3545;
  overflow-y: scroll;

}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
/* 

.heading {
  font-family: Tahoma, "Segoe UI", "Arial" !important;
} */


.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  max-width: 50%;
  color: white;
}
.add-button {
  margin-right: 0.5em;
  margin-bottom: 0.10em;
}
.button {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  color: var(--alethea-green);
}
.button:focus {
  outline:0;
}


.button-border {
  border: none;
  padding: 0.6em;
  border-radius: 0.25em;
  background: var(--alethea-green);
  color: #ffffff;
  /* font-size: 0.9em; */
}


.button-material {
  border: none;
  background: none;
  color: var(--alethea-green);
}

.button-material:disabled {
  color: #aaaaaa;
}

.button-border:disabled {
  background: #aaaaaa;
}


.card {
  filter: drop-shadow(-1px 1px 1px var(--light-grey));
}

.caret {
  width: 0;
  height: 0;
  display: inline-block;
  border: 8px solid transparent;
  align-items: center;
  transition: all 0.1s 0s;
  border-top-color: #555555;
} 
.caret.down{
  transform: rotate(0);
}
.caret.right{
  transform: rotate(-90deg);
}

.centered-text {
  text-align: center
}

.checkbox {
  margin-right: 0.5em;
}


.section-header{  
  background-color: white;
  color: #555555;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.form-control[readonly].status-text{
  background-color: white;
}


.header-item {
  width: 0%;
  margin: 0 auto;
}

.header-image {
  margin-top: 5em
}

.header-text {
  text-align: center;
  color: #999999;
}

.location-list {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}

.navbar-nav.navbar-center {
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
}


.remove-button {
  margin-top: 0.4em;
  padding: 0;
  /* color: white; */
}

.spacer {
  margin-top: 40px; /* define margin as you see fit */
}

.scroll {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.section-header{  
  background-color: white;
  color: var(--default-grey);
}